/// ref
/* eslint-disable camelcase */
import { vr, IValid } from '@util/utils/validator'
import { EmptyHttpError, getEmptyInvoke, getPost, post, hidePost, execHidePostValid } from '@util/utils/HttpClient'
import { adaptPassword } from '@util/utils/stringUtils'
import { checkPageResponse, ExecuteResponse, IdNameEntity, PageResponse } from '@util/models/ServerBaseEntity'
import { setTokenInfo as saveToken, clearToken, getToken, getRefreshToken, hasLogin } from '@/store'
import { IdEntity } from '@/util/models/ServerIdEntity'
export interface UserLoginByPassword {
  password: string
  username: string
}
export interface BackImageResponse {
  image: string | undefined
}
export class UserLoginInfo extends IValid implements UserLoginByPassword {
  @vr([{
    required: true,
    message: '登录密码不能为空'
  }, {
    min: 5,
    message: '登录密码最少输入5位'
  }])
  password: string

  @vr([{
    required: true,
    message: '登录账号不能为空'
  }, {
    min: 5,
    max: 50,
    message: '登录账号最少输入5位'
  }])
  username: string

  remember: boolean

  constructor() {
    super()
    this.username = ''
    this.password = ''
    this.remember = false
  }
}
export interface ServerAppResponse {
  app_name: string
  app_icon: string
  app_key: number
  app_desc: string
  app_url: string
  display_login?: boolean
}

export interface GetUserAppMenuResponse extends IdEntity {
  menu_name: string
  menu_icon: string
  menu_title: string
  menu_desc: string
}
export interface GetAuthAppMapResponse extends IdEntity {
  app_name: string
  app_url: string
  app_icon: string
  group_id: number
  groups: Array<IdNameEntity>
  menus: Array<GetUserAppMenuResponse>
}
interface LoginResponse {
  access_token: string
  refresh_token: string
  avatar: string
  username: string
  expired: number
}

const LoginUrl = {
  login: '/auth/login/pass',
  logout: '/auth/logout',
  refresh: '/auth/login/refresh',
  hashExpired: '/auth/login/hashExpired',
  getApps: '/auth/getApps',
  getBackImage: '/auth/getBackImage'
}

interface RefreshTokenRequest {
  refresh_token: string
}

interface ChangeGroupIdRequest {
  group_id: number
}

export interface TagInfoResponse {
  tag_name: string
  tag_remark: string
  expire: string
}
function setTokenInfo(resp: LoginResponse, remember: boolean | undefined) {
  saveToken({
    access_token: resp.access_token,
    refresh_token: resp.refresh_token,
    user_name: resp.username,
    avatar: resp.avatar,
    expired: resp.expired,
    remember
  })
}
export interface UserResetByPasswordRequest {
  username: string
  password: string
  mobile: string
  qq_number: string
}

async function clearTokenInfo() {
  await clearToken()
}
export class TokenApi {
  @execHidePostValid('/acc/license/gen')
  static async addLicense(request: { license_code: string }): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execHidePostValid('/auth/reset/pass')
  static async reset(request: UserResetByPasswordRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @getPost<BackImageResponse, string>('/auth/getBackImage', (resp) => resp?.image ?? '')
  static async getBingImage(): Promise<string> {
    throw new EmptyHttpError()
  }

  @getPost<PageResponse<ServerAppResponse>, Array<ServerAppResponse>>('/auth/getApps', (resp) => checkPageResponse(resp).list)
  static async getApps(): Promise<Array<ServerAppResponse>> {
    throw new EmptyHttpError()
  }

  @getPost<PageResponse<TagInfoResponse>, Array<TagInfoResponse>>('/acc/tag/query', (resp) => checkPageResponse(resp).list)
  static async getTags(): Promise<Array<TagInfoResponse>> {
    throw new EmptyHttpError()
  }

  @getPost<boolean, any>('/auth/login/hashExpired', (resp) => resp ?? false)
  private static async getTokenHashExpired(): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @post('/auth/login/refresh')
  private static async invokeRefresh(req: RefreshTokenRequest): Promise<LoginResponse> {
    throw new EmptyHttpError()
  }

  static async refresh() {
    const refreshToken = getRefreshToken()
    if (refreshToken === undefined || refreshToken == null) {
      throw new Error('refresh token not found')
    }
    const resp = await TokenApi.invokeRefresh({ refresh_token: refreshToken })
    setTokenInfo(resp, undefined)
    return true
  }

  @hidePost('/auth/login/pass')
  private static async invokeLogin(req: UserLoginByPassword): Promise<LoginResponse> {
    throw new EmptyHttpError()
  }

  @getPost<ExecuteResponse, boolean>('/auth/group', (resp) => resp?.success ?? false)
  public static async changeGroup(req: ChangeGroupIdRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @getPost<ExecuteResponse, boolean>('/auth/logout', (resp) => resp?.success ?? false)
  private static async invokeLogout(): Promise<boolean> {
    throw new EmptyHttpError()
  }

  static async tokenHashExpired(): Promise<boolean> {
    const token = getToken()
    if (token === undefined) {
      return Promise.resolve(false)
    }
    return TokenApi.getTokenHashExpired()
  }

  static async login(userInfo: UserLoginInfo) {
    if (userInfo === undefined) {
      throw new Error('user info not found')
    }
    const resp = await TokenApi.invokeLogin({
      username: userInfo.username,
      password: adaptPassword(userInfo.password)
    })
    setTokenInfo(resp, userInfo.remember)
  }

  static async logout(isServer: boolean = true) {
    if (isServer) {
      let resp: boolean | undefined
      if (hasLogin()) {
        resp = await TokenApi.invokeLogout()
      }
      clearTokenInfo()
      if (resp !== undefined && resp) {
        return true
      } else {
        return false
      }
    } else {
      clearTokenInfo()
      return true
    }
  }
}
