import { createStore, Store } from 'vuex'
import { tokenModule } from '@util/storage/userToken'
import { LoginTokenResponse } from '@util/models/TokenModels'
import { routerModule } from '@util/storage/routerCache'
import { InjectionKey } from 'vue'
interface IRootState {
  appDesc: string | undefined
}
export const key: InjectionKey<Store<IRootState>> = Symbol('storage key')
export const store = createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    tokenModule,
    routerModule
  }
})
export function hasLogin(): boolean {
  return store.getters['tokenModule/hasToken']
}
export function getToken(): string | undefined {
  return store.getters['tokenModule/getToken']
}
export function hashRemember(): boolean | undefined {
  return store.getters['tokenModule/hashRemember']
}
export function getRefreshToken(): string | undefined {
  return store.getters['tokenModule/getRefreshToken']
}
export function setTokenInfo(rep: LoginTokenResponse) {
  store.commit('tokenModule/updateToken', rep)
}
export async function clearToken() {
  store.commit('tokenModule/clearToken')
}
export async function clearHttpToken() {
  store.commit('tokenModule/clearHttpToken')
}
