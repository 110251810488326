import { BaseResponse } from '../models/ServerBaseEntity'

const errorMap: { [id: number]: string } = {
  1: '服务器响应失败,请重试!',
  9: '操作遇到严重错误，无法继续。请与管理员联系!',
  1102: '登录失败,账号或密码输出错误',
  1260: '授权码输人错误,无法使用',
  2133: '无法合并这个配置,因为它并不属于你',
  2135: '无法订阅当前项目，因为它是私有的',
  2134: '配置文件类型不正确,无法生成',
  2136: '只能订阅生成后的配置文件',
  2137: '每人只能创建一个类型的配置信息',
  2500: '你当前会员等级不足,无法保存私有配置信息',
  2501: '你当前会员等级不足,无法创建配置信息',
  2183: '你没有权限修改这个配置信息',
  2502: '只有高级会员或以上才能添加独立菜单'
}
const errorLog: { [id: number]: string } = {
  1: 'api调用失败!'
}

export function getError(resp: BaseResponse<any>) {
  if (errorLog[resp.code]) {
    console.error(new Error(`${errorLog[resp.code]},code:${resp.code},msg:${resp.message}`))
  }
  if (errorMap[resp.code] !== undefined) {
    return errorMap[resp.code]
  } else {
    return resp.message
  }
}
