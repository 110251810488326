import { Module } from 'vuex'
import { LocalStorageUtils } from './localStorage'
import axios from 'axios'
export interface IMenuCache {
  name: string
  icon: string | undefined
  title: string | undefined
  desc: string | undefined
}
export interface IAppGrpupItems {
  id: number
  name: string
  desc: string
}
export interface IAppGroupInfo {
  current: number
  items: Array<IAppGrpupItems>
}

export interface IAppInfo {
  appName: string | undefined
  appIcon: string | undefined
  appId: number | undefined
  group: IAppGroupInfo
  refKey: number | undefined
  menus: Array<IMenuCache>
}

export interface ILastPathInfo {
  name: string
  fullPath: string
}

class RouterCacheInfo {
  httpUrl: string | undefined
  lastPath: ILastPathInfo | undefined
  app: IAppInfo | undefined
  collapse: boolean | undefined
}
export interface IHttpUrl {
  http: string
  login: string
  token: string,
  hashHttps: boolean | undefined
}
let httpBaseURL: IHttpUrl | undefined
function replaceHttps(https: boolean, url: string) {
  if (url !== undefined) {
    if (https) {
      url = url.replace('http://', 'https://')
    } else {
      url = url.replace('https://', 'http://')
    }
  }
  return url
}
export function setHttpBaseUrl(url: IHttpUrl) {
  const isHttps = window.location.href.indexOf('https://') > -1
  url.http = replaceHttps(isHttps, url.http)
  url.login = replaceHttps(isHttps, url.login)
  url.token = replaceHttps(isHttps, url.token)
  url.hashHttps = isHttps
  httpBaseURL = url
  return url
}

export const routerModule: Module<RouterCacheInfo, any> = {
  namespaced: true,
  state: {
    httpUrl: httpBaseURL?.http,
    lastPath: undefined,
    app: undefined,
    collapse: false
  },
  getters: {
    getMenu(state: RouterCacheInfo) {
      if (state.app === undefined) {
        state.app = LocalStorageUtils.get<IAppInfo>('appInfo')
      }
      return state.app?.menus ?? []
    },
    getAppInfo(state: RouterCacheInfo): IAppInfo | undefined {
      if (state.app === undefined) {
        state.app = LocalStorageUtils.get<IAppInfo>('appInfo')
      }
      if (state.app === undefined) {
        return undefined
      }
      return state.app
    },
    getLoginUrl(state: RouterCacheInfo): string | undefined {
      return httpBaseURL?.login
    },
    getHttpUrl(state: RouterCacheInfo): string | undefined {
      state.httpUrl = state.httpUrl ?? httpBaseURL?.http
      return state.httpUrl
    },
    getLastUrl(state: RouterCacheInfo): ILastPathInfo | undefined {
      return state.lastPath
    },
    getCollapse(state: RouterCacheInfo): boolean | undefined {
      let key = state.collapse
      if (key === undefined) {
        key = LocalStorageUtils.get<boolean>('isCollapse')
        state.collapse = key
      }
      return key ?? true
    }
  },
  mutations: {
    updateToken(state: RouterCacheInfo) {
      if (state.app === undefined) {
        state.app = {
          appIcon: undefined,
          appName: undefined,
          appId: undefined,
          refKey: new Date().getTime(),
          group: {
            current: 0,
            items: []
          },
          menus: []
        }
      } else {
        state.app.refKey = new Date().getTime()
      }

      LocalStorageUtils.set<IAppInfo>('appInfo', state.app)
    },
    updateHttps(state: RouterCacheInfo, https: boolean) {
      axios.defaults.baseURL = state.httpUrl
    },
    setLastUrl(state: RouterCacheInfo, path: ILastPathInfo) {
      state.lastPath = path
    },
    setCollapse(state: RouterCacheInfo, collapse: boolean) {
      state.collapse = collapse
      LocalStorageUtils.set<boolean>('isCollapse', collapse)
    },
    updateAppInfo(state: RouterCacheInfo, app: IAppInfo) {
      state.app = app
      LocalStorageUtils.set<IAppInfo>('appInfo', app)
    },
    updateAppKey(state: RouterCacheInfo, appKey: number) {
      if (state.app === undefined) {
        state.app = {
          appIcon: undefined,
          appName: undefined,
          refKey: undefined,
          appId: appKey,
          group: {
            current: 0,
            items: []
          },
          menus: []
        }
      } else {
        state.app.appId = appKey
      }

      LocalStorageUtils.set<IAppInfo>('appInfo', state.app)
    },
    clearMenu(state: RouterCacheInfo) {
      let appKey: number | undefined
      if (state.app !== undefined) {
        appKey = state.app.appId
      }
      state.app = {
        appIcon: undefined,
        appName: undefined,
        refKey: undefined,
        appId: appKey,
        group: {
          current: 0,
          items: []
        },
        menus: []
      }
      LocalStorageUtils.set<IAppInfo>('appInfo', state.app)
    }
  }
}
