/* eslint-disable camelcase */
import { EmptyHttpError, execPostValid, formData, getPost, execHidePostValid } from '@/util/utils/HttpClient'
import { IValid, vr } from '@/util/utils/validator'
import { checkPageResponse, ExecuteResponse, PageRequest, PageResponse, UploadFileResponse } from '@util/models/ServerBaseEntity'
import { IdEntity } from '@util/models/ServerIdEntity'

export class RegisterAccountInfo extends IValid {
  @vr([{
    required: true,
    message: '用户名不能为空'
  }, {
    min: 5,
    message: '用户名最少输入5位'
  }])
  user_name: string = ''

  login_name: string = ''
  @vr([{
    required: true,
    message: '密码不能为空'
  }, {
    min: 5,
    message: '密码最少输入5位'
  }])
  password: string = ''

  @vr([{
    required: true,
    message: '邀请码不能为空'
  }, {
    min: 5,
    message: '邀请码最少输入5位'
  }])
  license: string = ''
}
class SaveAccountGroupRequest {
  group_name?: string;
  group_desc?: string;
}
export class EditAccountGroupRequest {
  id: number = 0
  group_name?: string;
  group_desc?: string;
}
class QueryAccountGroupRequest extends PageRequest {

}
export interface QueryAccountGroupResponse {
  id: number
  group_name?: string;
  group_desc?: string;
}
class UserBaseInfoRequest {

}
export interface UserBaseInfoResponse {
  avatar?: string
  user_name?: string
  qq_number?: string
  mobile?: string
}
export class EditUserBaseInfoRequest {
  avatar?: string
  user_name?: string
  qq_number?: string
  mobile?: string
}
class QueryAccountListRequest {

}
class QueryAccountListResponse {

}
class UpdateAccountRequest {

}
class AddAccountLicenseRequest {
  license_code: string = ''
}
export class AccountOperationApi {
  @execHidePostValid('/acc/group/insert')
  static async insertGroup(request: SaveAccountGroupRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execHidePostValid('/acc/group/edit')
  static async editGroup(request: EditAccountGroupRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execPostValid('/acc/group/remove')
  static async removeGroup(request: IdEntity): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @getPost<PageResponse<QueryAccountGroupResponse>, Array<QueryAccountGroupResponse>>('/acc/group/query', (resp) => checkPageResponse(resp).list)
  static async queryGroup(request: QueryAccountGroupRequest): Promise<Array<QueryAccountGroupResponse>> {
    throw new EmptyHttpError()
  }

  @getPost<UserBaseInfoResponse, UserBaseInfoResponse>('/acc/get', (resp) => resp)
  static async getBaseInfo(request: UserBaseInfoRequest): Promise<UserBaseInfoResponse> {
    throw new EmptyHttpError()
  }

  @execPostValid('/acc/edit')
  static async editBaseInfo(request: EditUserBaseInfoRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  static async uploadAvatar(request: File): Promise<UploadFileResponse> {
    const resp = await formData<UploadFileResponse>('/acc/avatar/upload', request)
    return resp ?? {
      url: '',
      filename: '',
      success: false
    }
  }

  @getPost<PageResponse<QueryAccountListResponse>, Array<QueryAccountListResponse>>('/acc/oauth/query', (resp) => checkPageResponse(resp).list)
  static async queryAuth(request: QueryAccountListRequest): Promise<Array<QueryAccountListResponse>> {
    throw new EmptyHttpError()
  }

  @execPostValid('/acc/oauth/update')
  static async updateAuth(request: UpdateAccountRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execHidePostValid('/acc/license/gen')
  static async addLicense(request: AddAccountLicenseRequest): Promise<boolean> {
    throw new EmptyHttpError()
  }

  @execHidePostValid('/auth/acc/register')
  static async registerAccount(request: RegisterAccountInfo): Promise<boolean> {
    throw new EmptyHttpError()
  }
}
