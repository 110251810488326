import { createApp } from 'vue'

import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import { hookRoute } from '@util/utils/RouterUtils'
import { store, getToken, key } from '@/store'
import { setHttpBaseUrl } from '@util/storage/routerCache'
import '@/assets/icon/iconfont.css'
import { initIcon, IconFontRoot } from '@util/utils/iconUtils'
import { initHttpClient } from '@util/utils/HttpClient'
import { ElMessageBox } from 'element-plus/es'
import StorageClientUtil from '@util/storage/storageClientUtil'
import 'element-plus/theme-chalk/src/message-box.scss'
import 'element-plus/theme-chalk/src/message.scss'
import 'element-plus/theme-chalk/src/loading.scss'
const iconData: IconFontRoot = require('@/assets/icon/iconfont.json')
const url = setHttpBaseUrl({
  http: process.env.VUE_APP_API_ROOT ?? '',
  login: process.env.VUE_APP_Login ?? '',
  token: process.env.VUE_APP_CORSS_URL ?? '',
  hashHttps: false
})
StorageClientUtil.setUrl(url.token)
const app = createApp(App)
initIcon(iconData)
app.use(store, key)
initHttpClient(store, router, (e: any) => {
  if (e.message !== undefined) { e = e.message }
  ElMessageBox({
    type: 'error',
    showClose: true,
    message: '请求服务器出现错误,' + e
  })
})
hookRoute(router, store, '管理')
app.use(router).mount('#app')
