import { Module } from 'vuex'
import { LocalStorageUtils } from './localStorage'
import { UserToken, LoginTokenResponse, UserBaseInfo } from './../models/TokenModels'

const UserTokenKey = 'userAccessToken'
function asyncLocalInfo(state: UserToken) {
  if (state === undefined) {
    state = new UserToken()
  }
  let localToken: UserToken | undefined
  if (state.token === undefined) {
    if (localToken === undefined) {
      localToken = LocalStorageUtils.get<UserToken>(UserTokenKey)
    }
    if (localToken !== undefined && localToken.avatar !== undefined) {
      state.avatar = localToken.avatar
    }
    if (localToken !== undefined && localToken.user_name !== undefined) {
      state.user_name = localToken.user_name
    }
    if (localToken !== undefined && localToken.refresh !== undefined) {
      state.refresh = localToken.refresh
    }
    if (localToken !== undefined && localToken.remember !== undefined) {
      state.remember = localToken.remember
    }
    if (localToken !== undefined && localToken.token !== undefined) {
      state.token = localToken.token
    }
  }
}
export const tokenModule: Module<UserToken, any> = {
  namespaced: true,
  state: {
    user_name: '',
    avatar: undefined,
    remember: undefined,
    token: undefined,
    refresh: undefined
  },
  getters: {
    hashRemember(state: UserToken): boolean | undefined {
      asyncLocalInfo(state)
      return state?.remember ?? false
    },
    hasToken(state: UserToken): boolean | undefined {
      asyncLocalInfo(state)
      if (state?.token?.token === undefined) {
        return false
      }
      if (state?.token?.expired === undefined) {
        return false
      }
      const startTime = new Date().getTime() / 1000
      if (startTime > state.token.expired) {
        return false
      }
      return true
    },
    getUserInfo(state: UserToken): UserBaseInfo {
      asyncLocalInfo(state)
      const ret = {
        user_name: state.user_name,
        avatar: state.avatar
      }
      return ret
    },
    getToken(state: UserToken): string | undefined {
      asyncLocalInfo(state)
      if (state?.token?.token === undefined) {
        return undefined
      }
      if (state?.token?.expired === undefined) {
        return undefined
      }
      const startTime = new Date().getTime() / 1000
      if (startTime > state.token.expired) {
        return undefined
      }
      return state.token.token
    },
    getRefreshToken(state: UserToken): string | undefined {
      asyncLocalInfo(state)
      if (state?.refresh?.token === undefined) {
        return undefined
      }
      if (state?.refresh?.expired === undefined) {
        return undefined
      }
      const startTime = new Date().getTime() / 1000
      if (startTime > state.refresh.expired) {
        return undefined
      }
      return state.refresh.token
    }
  },
  mutations: {
    clearToken(state: UserToken) {
      LocalStorageUtils.remove(UserTokenKey)
      state.refresh = undefined
      state.token = undefined
      state.user_name = ''
    },
    async changeRefresh(state: UserToken, token: string) {
      await asyncLocalInfo(state)
      const startTime = new Date().getTime() / 1000
      state.refresh = {
        token: token,
        expired: startTime + 7200
      }
      LocalStorageUtils.remove(UserTokenKey)
      LocalStorageUtils.set<UserToken>(UserTokenKey, state)
    },
    clearHttpToken(state: UserToken) {
      state.token = {
        token: '',
        expired: new Date().getTime() / 1000 + 9999
      }
      const localToken = LocalStorageUtils.get<UserToken>(UserTokenKey)
      if (localToken !== undefined) {
        localToken.token = state.token
        LocalStorageUtils.set<UserToken>(UserTokenKey, localToken)
      }
    },
    updateUserInfo(state: UserToken, req: UserBaseInfo) {
      const localToken = LocalStorageUtils.get<UserToken>(UserTokenKey)
      state.token = localToken?.token
      state.refresh = localToken?.refresh
      state.remember = localToken?.remember
      state.avatar = req.avatar
      state.user_name = req.user_name
      LocalStorageUtils.remove(UserTokenKey)
      LocalStorageUtils.set<UserToken>(UserTokenKey, state)
    },
    updateToken(state: UserToken, rep: LoginTokenResponse) {
      if (rep.access_token === undefined || rep.access_token === '') {
        throw new Error('get user token fail!')
      }
      if (rep.expired === undefined || rep.expired <= 0) {
        rep.expired = 7200
      }
      if (rep.user_name === undefined || rep.user_name === '') {
        throw new Error('get user name fail!')
      }
      if (rep.refresh_token === undefined || rep.refresh_token === '') {
        throw new Error('get user refresh token fail!')
      }
      const localToken = LocalStorageUtils.get<UserToken>(UserTokenKey)
      state.token = localToken?.token
      state.refresh = localToken?.refresh
      state.remember = localToken?.remember
      state.avatar = localToken?.avatar
      state.user_name = localToken?.user_name ?? ''
      const startTime = new Date().getTime() / 1000
      let hasUpdate = false
      if (rep.access_token !== undefined && rep.access_token !== '') {
        state.token = {
          token: rep.access_token,
          expired: startTime + rep.expired
        }
        hasUpdate = true
      }
      if (rep.refresh_token !== undefined && rep.refresh_token !== '') {
        state.refresh = {
          token: rep.refresh_token,
          // 2天
          expired: startTime + 172800
        }
        hasUpdate = true
      }
      if (rep.user_name !== undefined && rep.user_name !== '') {
        state.user_name = rep.user_name
        hasUpdate = true
      }
      if (rep.avatar !== undefined && rep.avatar !== '') {
        state.avatar = rep.avatar
        hasUpdate = true
      }
      if (rep.remember !== undefined) {
        state.remember = rep.remember
      }
      if (hasUpdate) {
        LocalStorageUtils.remove(UserTokenKey)
        LocalStorageUtils.set<UserToken>(UserTokenKey, state)
      }
    }
  },
  actions: {

  }
}
