import Home from '@/views/HomeView.vue'
import RefreshRedirect from '@/views/RefreshRedirect.vue'
import AccountHome from '@/views/AccountHome.vue'
import Login from '@/views/LoginView.vue'
export default [
  {
    path: '/account',
    component: AccountHome,
    name: '主页',
    meta: {
      hasRoot: true
    },
    children: []
  },
  {
    path: '/reLoad',
    component: RefreshRedirect,
    name: '刷新token跳转',
    meta: {
      hasRoot: true
    },
    children: []
  }, {
    path: '/login.html',
    component: Login,
    name: '登录',
    meta: {
      hasRoot: true
    },
    children: []
  }, {
    path: '/login',
    name: '登录2',
    redirect: '/login.html',
    meta: {
      hasRoot: true
    }
  }, {
    path: '/',
    name: '首页',
    redirect: '/login.html',
    meta: {
      hasRoot: true
    }
  }
  // , {
  //   path: '/',
  //   component: Home,
  //   name: '首页',
  //   meta: {
  //     hasRoot: true
  //   },
  //   children: []
  // }, {
  //   path: '/ref.html',
  //   component: Home,
  //   name: 'postmessage',
  //   meta: {
  //     hasRoot: true
  //   },
  //   children: []
  // }, {
  //   path: '/logout.html',
  //   component: Home,
  //   name: '登出',
  //   meta: {
  //     hasRoot: true
  //   },
  //   children: []
  // }, {
  //   path: '/reset.html',
  //   component: Home,
  //   name: '重置密码',
  //   meta: {
  //     hasRoot: true
  //   },
  //   children: []
  // }, {
  //   path: '/register.html',
  //   component: Home,
  //   name: '注册',
  //   meta: {
  //     hasRoot: true
  //   },
  //   children: []
  // }
]
