import { createRouter, createWebHistory } from 'vue-router'
import { routerUtils } from '@util/utils/RouterUtils'
import map from './routerMap'
routerUtils.use(map)
const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes: routerUtils.get()
})

export default router
